<template>
  <div>
    My Account
  </div>
</template>

<script>
  export default {
    name: 'Account'
  }
</script>

<style scoped>

</style>